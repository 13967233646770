import SiteDashboard from './dashboard';
import SiteLogin from './login';
import {sessionSelector} from "../../redux/slicers/sessionSlice";
import {useSelector} from "react-redux";

const SiteAdmin = () => {
    const {session} = useSelector(sessionSelector);

    return (
            <div className="tw-fixed tw-h-full tw-w-full tw-left-0 tw-top-0">
                {session ? <SiteDashboard /> : <SiteLogin />}
            </div>
    );
};


export default SiteAdmin;
