import api from '../helpers/axiosHelper.js';
import {ADMIN_LOGIN_API, ADMIN_SESSION_API, API_ROOT} from '../config/constant/apiConstant.js';
import {devConsoleLog, errorToast, storeLocal, successToast} from '../helpers/projectHelper.js';
import {loginLoading, sessionFail, sessionSuccess} from '../redux/slicers/sessionSlice.js';
import {USER_DATA} from "../config/constant/projectConstant";

export const adminLoginApi = (formData,callBack) => (dispatch) => {
    dispatch(loginLoading(true));
    api().root(API_ROOT).post(ADMIN_LOGIN_API).data(formData).success((a) => {
        const { message, result = {} } = a;
        successToast({ title: 'Success', msg: message });
        storeLocal(result, USER_DATA);
        storeLocal(result.access_token);
        dispatch(sessionSuccess())
        callBack?.()
    }).error((e) => {
        const { message: msg = '' } = e;
        devConsoleLog(e);
        dispatch(sessionFail());
        errorToast({ title: 'Login Failed', msg:'Invalid username/password' });
    }).send(() => {
        dispatch(loginLoading(false));
    });
};



export const adminSessionApi = (success,fail,loading) => {
    api().root(API_ROOT).post(ADMIN_SESSION_API).success((a) => {
        const { message, result = {} } = a;
        success?.(a)
    }).authFail401((e) => {
        fail?.()
    }).send(() => {
        loading(false)
    });
};