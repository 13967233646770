import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { FullScreenLoader } from '../../../components/basic/loader.js';
import { ADMIN_BLOG } from '../../../config/constant/routeConstant.js';

const SiteDashboard = () => {
   const router = useRouter();
   useEffect(() => {
      router.push(ADMIN_BLOG)
   },[])
   return <FullScreenLoader/>
};

export default SiteDashboard;
