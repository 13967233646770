import Link from 'next/link';
import {Button, Form, Icon, Image, Segment} from 'semantic-ui-react';
import {LOGO_IMG} from '../../../config/constant/imgConstant.js';
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {warningToast} from "../../../helpers/projectHelper";
import {adminLoginApi, adminSessionApi} from "../../../request/sessionRequest";
import {sessionSelector, sessionSuccess} from "../../../redux/slicers/sessionSlice";
import {WInput} from "../../../components/basic/input";
import {FullScreenLoader} from "../../../components/basic/loader";
import { SITE_ADMIN_FORGET } from '../../../config/constant/routeConstant.js';
import 'semantic-ui-css/semantic.min.css';
import 'tailwindcss/tailwind.css';

const SiteLogin = ({simple=false,onSuccess=null}) => {
    const [loading,setLoading]  = useState(true)
    const { loginLoading } = useSelector(sessionSelector);
    const [data, setData] = useState({});
    const dispatch = useDispatch();

    const handleChange = ({ name, value }) => {
        const formData = { ...data }
        if (name) {
            formData[name] = value;
        }
        setData(formData)
    };

    useEffect(() => {
        adminSessionApi(()=>{
           dispatch(sessionSuccess())
        },()=>{

        },setLoading)
    },[])

    const handleSubmit = (e) => {
        e.preventDefault();
        const { username = '', password = '' } = data;
        if (!username.trim() || !password.trim()) {
            warningToast({ title: 'Invalid', msg: 'Please fill the Email/Password fields' });
            return;
        }
        let loginData = {
            user_email: username,
            password: password
        }
        dispatch(adminLoginApi(loginData,()=>{
            if (simple){
                onSuccess?.()
            }
        }));
    };

    return (
        loading ? <FullScreenLoader/> : <div className={simple ?'':"tw-flex tw-text-center tw-bg-gray-100 tw-flex-col tw-box-border tw-pt-40 tw-h-full tw-items-center"}>
            <div className={simple ? '':'tw-w-3/12'}>
                <Image src={LOGO_IMG} centered className="tw-w-60" verticalAlign={'bottom'} />
                <Segment className="tw-rounded-3xl" stacked>
                    <Form onSubmit={handleSubmit} className="tw-p-2 tw-pt-10 tw-flex-col tw-space-y-7">
                        <WInput type="email" iconPosition="left" onChange={handleChange} name={"username"} className="tw-w-full" placeholder={'Email'} icon="user" />
                        <WInput iconPosition="left" onChange={handleChange} name={"password"} className="tw-w-full" type="password" placeholder={'Password'} icon={"key"} />
                        <Button type="submit" disabled={loginLoading} loading={loginLoading} primary className="tw-w-full">Submit</Button>
                    </Form>
                </Segment>
                {!simple && <div className="tw-text-blue-600">
                    <Icon name="info circle" className="tw-text-gray-600"/> <Link target="_blank" href={SITE_ADMIN_FORGET}>Forgot
                    Password</Link>
                </div>}
            </div>
        </div>
    );
};

export default SiteLogin;
